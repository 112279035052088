import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { UniverseService } from '@services/universe.service';
import { DimensionService } from '@services/dimension.service';
import { MetadataService } from '@services/metadata.service';

import { Universe } from '@classes/report/universe';
import { Dimension } from '@classes/report/dimension';
import { Metadata } from '@classes/report/meta-data';

@Injectable()
export class UniverseResolver  {
    constructor(
        private uiSrv: UiService,
        private universeSrv: UniverseService,
        private dimensionSrv: DimensionService,
        private metaSrv: MetadataService

    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Universe[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);
        return this.universeSrv.get()
            .pipe(
                tap(() => this.uiSrv.routeLoadingStateChanged.next(false))
            );
    }
}
